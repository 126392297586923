<template>
    <div class="preview_area show_sidebar" :class="{ active : modelValue }" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'settings' ? 'active' : ''" @click="tab = 'settings'">Sign-Up Settings</li>
                <li :class="tab === 'notification' ? 'active' : ''" @click="tab = 'notification'">Notifications</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <Form @submit="handleSubmit" v-slot="{ errors }" class="setting-form">
                    <div class="content_area">
                        <div class="content_wpr">
                            <div class="section_content w-100" v-if="tab === 'settings'">
                                <div class="section_header">
                                    <h2>Client ID Sign-Up URL</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr has_suffix">
                                                <Field autocomplete="off" type="text" name="url" v-model="url" placeholder="https://onboardme.net/16ef71" readonly/>
                                                <span class="suffix pointer" @click="copyLink">Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'settings'">
                                <div class="section_header">
                                    <h2>Opt-in Settings</h2>
                                </div>
                                <div class="color_container mt-2 mb-2">
                                    <ul class="optin_list">
                                        <li>
                                            <label for="optin-fname" class="switch_option capsule_btn">
                                                <h5 class="large">First Name</h5>
                                                <input type="checkbox" id="optin-fname" :true-value="1" :false-value="0" v-model="form.optin_fields.first_name" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-lname" class="switch_option capsule_btn">
                                                <h5 class="large">Last Name</h5>
                                                <input type="checkbox" id="optin-lname" :true-value="1" :false-value="0" v-model="form.optin_fields.last_name" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-email" class="switch_option capsule_btn">
                                                <h5 class="large">Email</h5>
                                                <input type="checkbox" id="optin-email" :true-value="1" :false-value="0" v-model="form.optin_fields.email" hidden :disabled="true">
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-phone" class="switch_option capsule_btn">
                                                <h5 class="large">Phone</h5>
                                                <input type="checkbox" id="optin-phone" :true-value="1" :false-value="0" v-model="form.optin_fields.phone" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-address" class="switch_option capsule_btn">
                                                <h5 class="large">Address</h5>
                                                <input type="checkbox" id="optin-address" :true-value="1" :false-value="0" v-model="form.optin_fields.address" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-city" class="switch_option capsule_btn">
                                                <h5 class="large">City</h5>
                                                <input type="checkbox" id="optin-city" :true-value="1" :false-value="0" v-model="form.optin_fields.city" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-state" class="switch_option capsule_btn">
                                                <h5 class="large">State</h5>
                                                <input type="checkbox" id="optin-state" :true-value="1" :false-value="0" v-model="form.optin_fields.state" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-zip" class="switch_option capsule_btn">
                                                <h5 class="large">Postal Code</h5>
                                                <input type="checkbox" id="optin-zip" :true-value="1" :false-value="0" v-model="form.optin_fields.zip" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            <label for="optin-dob" class="switch_option capsule_btn">
                                                <h5 class="large">DOB</h5>
                                                <input type="checkbox" id="optin-dob" :true-value="1" :false-value="0" v-model="form.optin_fields.dob" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="section_header mt-5">
                                    <h2>Sign-Up Settings</h2>
                                </div>
                                <div class="color_container settings_preview mt-2">
                                    <div class="setting_wpr">
                                        <label for="card_icon" class="switch_option capsule_btn m-0 border-0 ">
                                            <h5 class="large">Display Card Icon</h5>
                                            <input type="checkbox" id="card_icon" v-model="form.display_card_icon" :true-value="1" :false-value="0" hidden />
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mt-1 mb-3" v-if="form.display_card_icon">
                                            <image-upload v-model="form.card_icon" image-type="profile-pic" ref="member-id-icon-upload" />
                                        </div>
                                        <label for="display_card_image" class="switch_option capsule_btn m-0 border-0 ">
                                            <h5 class="large">Display Card Image</h5>
                                            <input type="checkbox" name="display_card_image" id="display_card_image" v-model="form.display_card_image" :true-value="1" :false-value="0" hidden />
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mt-1 mb-3" v-if="form.display_card_image">
                                            <image-upload v-model="form.card_image" image-type="cover-medium" ref="member-id-cover-upload" />
                                            <!-- <image-upload v-model="form.card_image" image-type="dashboard-thumb" ref="member-id-cover-upload" /> -->
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Card Message</label>
                                                <div class="field_wpr m-0" :class="{'has-error': errors.card_title}">
                                                    <Field autocomplete="off" type="text" name="card_title" v-model="form.card_title" label="card title" rules="required" placeholder="Enter your details below to create your Thrive Coach member ID" />
                                                </div>
                                                <ErrorMessage name="card_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Button Text</label>
                                                <div class="field_wpr m-0" :class="{'has-error': errors.btn_text}">
                                                    <Field autocomplete="off" type="text" name="btn_text" v-model="form.btn_text" rules="required" label="button text" placeholder="Send My ID" />
                                                </div>
                                                <ErrorMessage name="btn_text" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Background Color</label>
                                                <div class="">
                                                    <Field autocomplete="off" name="link_color" v-model="form.btn_color" type="text" placeholder="#2F7FED">
                                                        <color-picker v-model="form.btn_color" placeholder="#2F7FED" />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Text Color</label>
                                                <div class="">
                                                    <Field autocomplete="off" name="button_color" v-model="form.text_color" type="text" placeholder="#FFF">
                                                        <color-picker v-model="form.text_color" placeholder="#FFF" />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <label for="privacy" class="switch_option capsule_btn p-0 border-0 mt-5 mb-4">
                                            <h5 class="large">Privacy</h5>
                                            <input type="checkbox" name="is_privacy" id="privacy" v-model="form.is_privacy" :true-value="1" :false-value="0" hidden />
                                            <div><span></span></div>
                                        </label>
                                        <div class="form_grp p-0 mt-2 mb-4" v-if="form.is_privacy == 1">
                                            <div class="group_item">
                                                <label class="input_label">Link To Privacy Policy</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="privacy_url" v-model="form.privacy_url" placeholder="ex. https://yourprivacyurl.com" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Privacy Text</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="privacy_text" v-model="form.privacy_text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sample_preview">
                                        <div class="mb-5" v-if="form.display_card_icon">
                                            <h3 class="sub_heading2 mb-3">Preview</h3>
                                            <img :src="form.card_icon ? form.card_icon : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon">
                                        </div>
                                        <div class="mb-5" v-if="form.display_card_image">
                                            <h3 class="sub_heading2 mb-3">Preview</h3>
                                            <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner m-0">
                                        </div>
                                        <h3 class="sub_heading2 mb-3">Preview</h3>
                                        <div class="drawer_area">
                                            <!-- <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner" v-if="form.display_card_image"> -->
                                            <div class="signup_portal" :class="{'no_cover' : !form.display_card_image && form.display_card_icon}">
                                                <!-- <img :src="form.card_icon ? form.card_icon : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="form.display_card_icon"> -->
                                                <div class="heading" v-html="replaceVariables(form.card_title)"></div>
                                                <ul class="field_list">
                                                    <li>
                                                        <div class="label">First Name</div>
                                                        <div class="field_box"></div>
                                                    </li>
                                                    <li>
                                                        <div class="label">Last Name</div>
                                                        <div class="field_box"></div>
                                                    </li>
                                                </ul>
                                                <div class="primarybtn" :style="`background-color: ${form.btn_color}; color: ${form.text_color};`">{{ form.btn_text }}</div>
                                                <div class="privacy" v-if="form.is_privacy">{{form.privacy_text}} <a>Privacy Policy</a></div>
                                                <div class="copyright">© Thrive Coach 2022. All rights reserved</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="capsule_elm pt-4">
                                    <h3 class="sub_heading2">Add Tag</h3>
                                    <label for="add-tag" class="switch_option capsule_btn">
                                        <input type="checkbox" id="add-tag" :true-value="1" :false-value="0" v-model="form.add_tags" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="setting_wpr" v-if="form.add_tags">
                                    <div class="field-wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Select Tags
                                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                                </label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                    <Field autocomplete="off" name="tag" v-model="form.tags" rules="required">
                                                        <multiselect
                                                            v-model="form.tags"
                                                            mode="tags"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :group-select="true"
                                                            :options="tagsGroup"
                                                            :groups="true"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            placeholder="Select Tags"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                {{ option.name }}
                                                                <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                >
                                                                    <span class="multiselect-tag-remove-icon"></span>
                                                                </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="tag" class="text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="createTags" class="form_grp">
                                            <Form v-slot="{ errors }" ref="tag-form">
                                                <div class="group_item">
                                                    <label class="input_label">Create a New Tag</label>
                                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                    </div>
                                                    <ErrorMessage name="name" class="text-danger" />
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div class="capsule_elm pt-4">
                                    <h3 class="sub_heading2">Assign client portal</h3>
                                    <label for="assign-portal" class="switch_option capsule_btn">
                                        <input type="checkbox" id="assign-portal" :true-value="1" :false-value="0" v-model="form.assign_portal" @change="handlePortalAssign" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'notification'">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <h3 class="sub_heading2">Sending Method</h3>
                                    <div class="setting_wpr mt-3">
                                        <div class="section_wpr mb-2">
                                            <div class="edit_section border">
                                                <sending-method v-model="form.notif_method" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="setting_wpr mt-2">
                                    <div v-if="form.notif_method === 1 || form.notif_method === 3">
                                        <div class="capsule_elm pt-2">
                                            <h3 class="sub_heading2">Email</h3>
                                            <label for="email_alert" class="switch_option capsule_btn m-0">
                                                <input type="checkbox" id="email_alert" v-model="emailAlert" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="setting_wpr mb-4" v-if="emailAlert">
                                            <email-component v-if="loadEditor" v-model="emailContent" :errors="errors" :handle-default-email="handleDefaultEmail" ref="email-component" />
                                        </div>
                                    </div>
                                    <div v-if="form.notif_method === 2 || form.notif_method === 3">
                                        <div class="capsule_elm pt-2">
                                            <h3 class="sub_heading2">SMS Setup</h3>
                                            <label for="sms_alert" class="switch_option capsule_btn">
                                                <input type="checkbox" id="sms_alert" v-model="smsAlert" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="setting_wpr" v-if="smsAlert">
                                            <sms-component v-if="loadEditor" v-model="form.delivery_sms" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" ref="sms-component" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    <div class="action_wpr">
                        <button :disabled="memberIdLoader" type="button" class="btn cancel_btn" @click="closeModal">Exit</button>
                        <button :disabled="memberIdLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="memberIdLoader"></i>{{ memberIdLoader ? ' Saving' : 'Save'}}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'settings'">
                <div class="header">
                    <div class="section_header m-0">
                        <h2>Preview</h2>
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="recovery_page bg-light" :class="{'no_cover' : !form.display_card_image && form.display_card_icon}">
                            <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-sm-cover.svg')" alt="" class="banner" v-if="form.display_card_image">
                            <div class="content_body">
                                <img :src="form.card_icon ? form.card_icon : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="form.display_card_icon">
                                <h3 class="heading" v-html="replaceVariables(form.card_title)"></h3>
                                <div class="setting_wpr">
                                    <div class="form_grp" v-if="form.optin_fields.first_name">
                                        <div class="group_item">
                                            <label class="input_label">First Name</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="first_name" placeholder="John" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.last_name">
                                        <div class="group_item">
                                            <label class="input_label">Last Name</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="last_name" placeholder="Doe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.email">
                                        <div class="group_item">
                                            <label class="input_label">E-mail</label>
                                            <div class="field_wpr has_prefix">
                                                <Field autocomplete="off" type="text" name="email" placeholder="john.doe@onboardme.io" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.phone">
                                        <div class="group_item">
                                            <label class="input_label">Phone</label>
                                            <div class="field_wpr has_prefix">
                                                <Field autocomplete="off" type="text" name="phone" placeholder="+1XXXXXX6789" />
                                                <span class="prefix"><i class="fas fa-phone-alt"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.address">
                                        <div class="group_item">
                                            <label class="input_label">Address</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="address" placeholder="Enter Addres Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.city">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="city" placeholder="Enter City Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.state">
                                        <div class="group_item">
                                            <label class="input_label">State</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="state" placeholder="Enter Addres Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.zip">
                                        <div class="group_item">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="zip" placeholder="Enter Postal Code Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.dob">
                                        <div class="group_item">
                                            <label class="input_label">DOB</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" name="date_of_birth">
                                                    <datepicker autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn" :style="`background-color: ${form.btn_color}; color: ${form.text_color};`">{{ form.btn_text }}</button>
                                    <p class="privacy" v-if="form.is_privacy">{{form.privacy_text}} <a :href="form.privacy_url">Privacy Policy</a></p>
                                    <p class="copyright">© Thrive Coach 2022. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'notification'">
                <notification-preview v-model="preview" :full-preview="toggleFullPreview" :isEmail="form.notif_method == 1 || form.notif_method == 3" :email="emailContent" :isSms="form.notif_method == 2 || form.notif_method == 3"  :sms="form.delivery_sms" ref="notification-preview" />
            </div>

            <full-preview v-model="fullPreview">
                <div class="dashboard" v-if="tab === 'settings'">
                    <div class="recovery_page" :class="{'no_cover' : !form.display_card_image && form.display_card_icon}">
                        <div class="page_container">
                            <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-sm-cover.svg')" alt="" class="banner" v-if="device == 'desk' && form.display_card_image">
                            <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-sm-cover.svg')" alt="" class="banner" v-if="device == 'tab' && form.display_card_image">
                            <img :src="form.card_image ? form.card_image : require('@/assets/images/thumb/default-sm-cover.svg')" alt="" class="banner" v-if="device === 'cell' && form.display_card_image">
                            <div class="content_body">
                                <img :src="form.card_icon ? form.card_icon : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="form.display_card_icon">
                                <h3>{{ replaceVariables(form.card_title) }}</h3>
                                <div class="setting_wpr">
                                    <div class="form_grp" v-if="form.optin_fields.first_name || form.optin_fields.last_name">
                                        <div class="group_item" v-if="form.optin_fields.first_name">
                                            <label class="input_label">First Name</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="first_name" placeholder="John" />
                                            </div>
                                        </div>
                                        <div class="group_item" v-if="device !== 'cell' && form.optin_fields.last_name">
                                            <label class="input_label">Last Name</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="last_name" placeholder="Doe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="device == 'cell' && form.optin_fields.last_name">
                                        <div class="group_item">
                                            <label class="input_label">Last Name</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="last_name" placeholder="Doe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.email">
                                        <div class="group_item">
                                            <label class="input_label">E-mail</label>
                                            <div class="field_wpr has_prefix">
                                                <Field autocomplete="off" type="text" name="email" placeholder="john.doe@onboardme.io" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.phone || form.optin_fields.address">
                                        <div class="group_item" v-if="form.optin_fields.phone">
                                            <label class="input_label">Phone</label>
                                            <div class="field_wpr has_prefix">
                                                <Field autocomplete="off" type="text" name="phone" placeholder="+1XXXXXX6789" />
                                                <span class="prefix"><i class="fas fa-phone-alt"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item" v-if="device !== 'cell' && form.optin_fields.address">
                                            <label class="input_label">Address</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="address" placeholder="Enter Address Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="device == 'cell' && form.optin_fields.address">
                                        <div class="group_item">
                                            <label class="input_label">Address</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="address" placeholder="Enter Address Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.city || form.optin_fields.state">
                                        <div class="group_item" v-if="form.optin_fields.city">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="first_name" placeholder="Enter City Here..." />
                                            </div>
                                        </div>
                                        <div class="group_item" v-if="device !== 'cell' && form.optin_fields.state">
                                            <label class="input_label">State</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="state" placeholder="Enter State Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="device == 'cell' && form.optin_fields.state">
                                        <div class="group_item">
                                            <label class="input_label">State</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="state" placeholder="Enter State Here..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="form.optin_fields.zip || form.optin_fields.dob">
                                        <div class="group_item" v-if="form.optin_fields.zip">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" type="text" name="zip" placeholder="Enter Postal Code Here..." />
                                            </div>
                                        </div>
                                        <div class="group_item" v-if="device !== 'cell' && form.optin_fields.dob">
                                            <label class="input_label">DOB</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" name="date_of_birth">
                                                    <datepicker autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="device == 'cell' && form.optin_fields.dob">
                                        <div class="group_item">
                                            <label class="input_label">DOB</label>
                                            <div class="field_wpr">
                                                <Field autocomplete="off" name="date_of_birth">
                                                    <datepicker autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn" :style="`background-color: ${form.btn_color}; color: ${form.text_color}`">{{ form.btn_text }}</button>
                                    <p class="privacy" v-if="form.is_privacy">{{form.privacy_text}} <a :href="form.privacy_url">Privacy Policy</a></p>
                                    <p class="copyright">© Thrive Coach 2022. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dashboard" v-if="tab === 'notification'">
                    <email-preview :email="emailContent" v-if="activePreview == 'email'"/>
                    <div v-if="activePreview == 'sms'">
                        <div class="content_preview">
                            <p v-html="form.delivery_sms"></p>
                        </div>
                    </div>
                </div>
            </full-preview>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { copyText } from 'vue3-clipboard'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
    const NotificationPreview = defineAsyncComponent(() => import('@/components/NotificationPreview'))
    const FullPreview = defineAsyncComponent(() => import('@/components/FullPreview'))
    const EmailPreview = defineAsyncComponent(() => import('@/components/EmailPreview'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Id Dashboard',

        data () {
            return {
                tab: 'settings',
                preview: true,
                fullPreview: false,
                url: '',
                notificType: 'email',
                form: {
                    card_title: '',
                    btn_text: '',
                    btn_color: '#407fed',
                    text_color: '#ffffff',
                    card_image: '',
                    card_icon: '',
                    display_card_image: 1,
                    display_card_icon: 1,
                    is_privacy: 1,
                    privacy_url: '',
                    privacy_text: '',
                    notif_method: 1,
                    delivery_sms: '',
                    optin_fields: {
                        first_name: 1,
                        last_name: 1,
                        email: 1,
                        phone: 1,
                        address: 0,
                        city: 0,
                        state: 0,
                        zip: 0,
                        dob: 0,
                    },
                    add_tags: 0,
                    assign_portal: 0,
                    tags: [],
                },
                emailContent: {
                    subject: 'Here is your member ID {{first_name}}',
                    email: '',
                    show_email_logo: 0,
                    email_logo: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                },
                emailAlert: true,
                smsAlert: true,
                loadEditor: false,
                device: 'desk',
                activePreview: '',
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'client-id',
                },
            }
        },

        props: {
            modelValue: Boolean,
        },

        watch: {
            settings () {
                const vm = this;
                vm.resetForm();
            },

            modelValue (value) {
                const vm = this;
                vm.loadEditor = false;

                if (value) {
                    if (Object.keys(vm.settings).length == 0) {
                        vm.getMemberIdSetting();
                    }

                    vm.resetForm();

                    setTimeout(function () {
                        vm.loadEditor = true;
                    }, 1000);

                    if (vm.tagsGroup.length == 0) {
                        vm.getTags();
                    }
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageUpload,
            NotificationPreview,
            FullPreview,
            EmailPreview,
        },

        computed: {
            ...mapState({
                memberIdLoader: state => state.memberIdModule.memberIdLoader,
                settings: state => state.memberIdModule.memberIdSetting,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                whiteLabel: state => state.authModule.whiteLabel,
                tagsGroup: state => state.tagModule.tagsGroup,
                tagLoader: state => state.tagModule.tagLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            window.addEventListener("resize", vm.windowResizeEventHandler);
            vm.windowResizeEventHandler();
        },

        unmounted () {
            const vm = this;

            window.removeEventListener("resize", vm.windowResizeEventHandler);
        },

        methods: {
            ...mapActions({
                storeMemberIdSetting: 'memberIdModule/storeMemberIdSetting',
                getMemberIdSetting: 'memberIdModule/getMemberIdSetting',
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            copyLink () {
                const vm = this;

                copyText(vm.url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            handleSubmit (form, { setFieldError } ) {
                const vm = this;

                vm.form.delivery_subject = vm.emailContent.subject;
                vm.form.delivery_email   = vm.emailContent.email;
                vm.form.email_logo       = vm.emailContent.email_logo;
                vm.form.show_email_logo  = vm.emailContent.show_email_logo;
                vm.form.is_email_logo    = vm.emailContent.is_email_logo;
                vm.form.setFieldError    = setFieldError;

                vm.storeMemberIdSetting(vm.form);
            },

            resetForm () {
                const vm = this;

                var message   = 'Hi {{first_name}} here is your unique member ID for {{company_name}}: {{member_id}}.\n'
                message      += '\n'
                message      += 'Cheers!\n'
                message      += '{{company_name}}'

                var content = `<p>Hi {{first_name}} here is your unique member ID for {{company_name}}.</p>
                             <p>You can use this ID to submit any document, form, referrals, review, or progress report and it will be tracked to your member account.</p>
                             <p><br>Please write this down somewhere you won't forget it.</p>
                             <p>Client Name: {{full_name}}</p>
                             <p>Client ID: {{member_id}}<br><br></p>
                             <p>Cheers!</p>
                             <p>Thrive Coach</p>`;

                vm.url = vm.settings.url ? vm.settings.url : '';
                vm.form = {
                    card_title: vm.settings.card_title ? vm.settings.card_title : 'Enter your details below to create your {{company_name}} member ID.',
                    btn_text: vm.settings.btn_text ? vm.settings.btn_text : 'Send My Id',
                    btn_color: vm.settings.btn_color ? vm.settings.btn_color : '#407fed',
                    text_color: vm.settings.btn_txtcolor ? vm.settings.btn_txtcolor : '#ffffff',
                    card_image: vm.settings.card_image ? vm.settings.card_image : '',
                    card_icon: vm.settings.card_icon ? vm.settings.card_icon : '',
                    display_card_icon: vm.settings.display_card_icon ? vm.settings.display_card_icon : 0,
                    display_card_image: vm.settings.display_card_image ? vm.settings.display_card_image : 0,
                    is_privacy: vm.settings.is_privacy ? vm.settings.is_privacy : 1,
                    privacy_url: vm.settings.privacy_url ? vm.settings.privacy_url : '',
                    privacy_text: vm.settings.privacy_text ? vm.settings.privacy_text : 'Your information is protected',
                    notif_method: vm.settings.notif_method ? vm.settings.notif_method : 1,
                    delivery_sms: vm.settings.delivery_sms ? vm.settings.delivery_sms : '',
                    optin_fields: vm.settings.optin_fields ? JSON.parse(vm.settings.optin_fields) : {},
                    add_tags: vm.settings.add_tags ? vm.settings.add_tags : 0,
                    assign_portal: vm.settings.assign_portal ? vm.settings.assign_portal : 0,
                    tags: vm.settings.tags ? vm.settings.tags : [],
                };

                if (vm.form.optin_fields.first_name == undefined) {
                    vm.form.optin_fields = {
                        first_name: 1,
                        last_name: 1,
                        email: 1,
                        phone: 1,
                        address: 0,
                        city: 0,
                        state: 0,
                        zip: 0,
                        dob: 0,
                    };
                }

                vm.emailContent = {
                    defaultHandler: true,
                    subject: vm.settings.delivery_subject ? vm.settings.delivery_subject : 'Here is your member ID {{first_name}}',
                    email: vm.settings.delivery_email ? vm.settings.delivery_email : content,
                    show_email_logo: vm.settings.show_email_logo ? vm.settings.show_email_logo : '',
                    email_logo: vm.settings.email_logo ? vm.settings.email_logo : '',
                    is_email_logo: vm.settings.is_email_logo ? vm.settings.is_email_logo : 0,
                    is_signature: vm.settings.is_signature ? vm.settings.is_signature : 0,
                    signature_id: vm.settings.signature_id ? vm.settings.signature_id : 0,
                };
            },

            handleDefaultEmail () {
                const vm = this;

                vm.emailContent = {
                    defaultEmail: true,
                    subject:      'Here is your member ID {{first_name}}',
                    email:        `<p>Hi {{first_name}} here is your unique member ID for {{company_name}}.</p>
                                   <p>You can use this ID to submit any document, form, referrals, review, or progress report and it will be tracked to your member account.</p>
                                   <p><br>Please write this down somewhere you won't forget it.</p>
                                   <p>Client Name: {{full_name}}</p>
                                   <p>Client ID: {{member_id}}<br><br></p>
                                   <p>Cheers!</p>
                                   <p>Thrive Coach</p>`
                }
            },

            handleDefaultSms () {
                const vm = this;
                vm.form.delivery_sms = 'Hi {{first_name}} here is your unique member ID for {{company_name}}: {{member_id}}.\n';
                vm.form.delivery_sms += '\n';
                vm.form.delivery_sms += 'Cheers!\n';
                vm.form.delivery_sms += '{{company_name}}';
            },

            toggleFullPreview (val) {
                const vm = this;

                vm.activePreview = vm.$refs['notification-preview'].activePreview;

                vm.fullPreview   = val;
            },

            toggleSectionbar () {
                const ls_bar = document.querySelector('.global_setting');

                if (ls_bar.classList.contains('show_m_sidebar')) {
                    ls_bar.classList.remove('show_m_sidebar');
                } else {
                    ls_bar.classList.add('show_m_sidebar');
                }
            },

            windowResizeEventHandler () {
                const vm = this;

                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handlePortalAssign (e) {
                const vm = this;

                if (vm.form.assign_portal) {
                    const options = Helper.swalWarningOptions('', 'If you have default content enabled via the (all clients) toggle in your client portal all contacts who obtained via Client ID page will receive immediate access. If no default content is active we recommend leaving this off and using tagging and journeys to assign the desired assets via a workflow.', 'Ok');

                    options.customClass = { htmlContainer: 'mt-5 pt-2' };

                    Swal.fire(options);
                }
            },
        }
    }
</script>

<style scoped>
    /* .area_body.desk .dashboard {
        align-items: center;
        background: #fafafb;
    } */
    .area_body.tab .dashboard, .area_body.cell .dashboard{
        background: #f5f5f5;
    }
    .recovery_page {
        /* border: 1px solid #eee; */
        align-items: center;
        background: #f5f5f5;
        line-height: 0;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .recovery_page .banner {
        /* height: 220px; */
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .page_container{
        background: #fff;
        max-width: 650px;
        width: 100%;
        margin: 30px auto;
        border-radius: 10px;
        overflow: hidden;
    }
    .cell .page_container{
        margin: 0 auto;
        border-radius: 0;
        height: 100%;
    }
    .recovery_page .content_body {
        padding: 45px 40px 40px 40px;
    }

    .mail_brand {
        background: #e4efff;
        border-radius: 8px 8px 0 0;
        padding: 15px 20px;
    }

    .recovery_page h3 {
        font-size: 20px;
        line-height: 28px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 10px;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }

    .recovery_page .icon {
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        display: block;
        border: 3px solid #fff;
    }
    .desk .recovery_page .icon{
        width: 120px;
        height: 120px;
        margin: -105px auto 40px auto;
    }
    .desk .recovery_page .setting_wpr{
        margin-top: 30px;
    }
    .desk .recovery_page .form_grp{
        margin-bottom: 10px;
    }
    .desk .primary_btn{
        margin: 30px 0 0 0;
    }
    .tab .recovery_page .icon{
        width: 120px;
        height: 120px;
        margin: -105px auto 40px auto;
    }
    .tab .recovery_page .setting_wpr{
        margin-top: 20px;
    }
    .tab .primary_btn{
        margin: 30px 0 0 0;
    }
    .cell .recovery_page .icon{
        width: 100px;
        height: 100px;
        margin: -75px auto 30px auto;
    }
    .cell .recovery_page .setting_wpr{
        margin-top: 20px;
    }
    .recovery_page.no_cover{
        margin-top: 70px;
    }

    .recovery_page h5 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
    }

    .recovery_page .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    .recovery_page .privacy {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 25px 0 10px;
    }

    .recovery_page .privacy a {
        color: #2f7eed;
        text-decoration: none;
    }

    .recovery_page .copyright {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 10px 0 0;
    }

    .preview_area {
        display: block;
    }

    .preview_area .close_btn.right {
        right: 15px;
        top: 15px;
        left: auto;
        position: absolute;
    }
    .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .tab .recovery_page.no_cover{
        margin-top: 70px;
    }
    .cell .recovery_page{
        /* min-height: 610px; */
    }
    .cell .recovery_page.no_cover{
        margin-top: 70px;
    }

    .cell .recovery_page .content_body {
        padding: 25px;
    }
    .cell .recovery_page h3 {
        font-size: 18px;
        line-height: 26px;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .cell .recovery_page .privacy{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 5px 0;
    }
    .cell .recovery_page .copyright{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 5px 0;
    }
    .cell .form_grp{
        padding: 13px 0;
    }
    .cell .setting_wpr .group_item .input_label{
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
    }
    .cell .field_wpr{
        border-radius: 4px;
    }
    :deep(.cell .field_wpr input){
        height: 35px;
        font-size: 13px;
    }
    .cell .field_wpr.has_prefix{
        padding-left: 40px;
    }
    .cell .field_wpr.has_prefix .prefix{
        width: 40px;
    }
    .cell .field_wpr.has_prefix .prefix i{
        font-size: 14px;
    }
    .cell .primary_btn{
        height: 40px;
        font-size: 13px;
        border-radius: 4px;
        margin: 25px 0 20px 0;
    }
    /* @media(max-width: 1400px){
        .preview_area .preview_area .recovery_page{
            transform: scale(0.8);
        }
    } */

    .setting-form{
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 5px;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .msg_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .msg_preview p{
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .tab .msg_preview p{
        font-size: 14px;
        line-height: 20px;
    }
    .desk .msg_preview h2 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }
    .tab .msg_preview h2 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
    }
    .msgFooter p{
        font-size: 13px;
        line-height: 21px;
    }
    .cell .msg_preview{
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        width: auto;
    }
    .cell .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .cell .msg_preview:after, .cell .sms_preview::after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .cell .sms_preview:after{
        right: 30px;
        left: auto;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview p, .cell .sms_preview p{
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .cell .msg_preview p span{
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter p {
        font-size: 11px;
        line-height: 17px;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
        /* border-bottom: 1px solid #eaeaea; */
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .upload_image :deep(.tab_content){
        height: auto;
    }

    :deep(.content_preview) {
        padding: 30px;
        background: #fff;
        /* border: 1px solid #eaeaea; */
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin: 50px auto;
        position: relative;
        border-radius: 10px;
    }
    :deep(.tab .content_preview) {
        margin: 20px;
        width: calc(100% - 40px);
    }
    :deep(.cell .content_preview) {
        padding: 15px 20px;
        margin: 15px;
        width: calc(100% - 30px);
    }

    :deep(.desk .msg_preview p), :deep(.desk .content_preview p) {
        font-size: 16px !important;
        line-height: 32px !important;
    }

    :deep(.tab .msg_preview p), :deep(.tab .content_preview p) {
        font-size: 16px !important;
        line-height: 32px !important;
    }

    :deep(.cell .msg_preview p), :deep(.cell .content_preview p) {
        font-size: 16px !important;
        line-height: 32px !important;
    }

    .optin_list {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -10px;
    }

    .optin_list li {
        padding: 10px;
        width: 50%;
    }

    .optin_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .settings_preview{
        display: flex;
        padding: 20px;
    }
    .settings_preview .setting_wpr{
        flex: 1 1 auto;
    }
    .settings_preview .sample_preview{
        width: 300px;
        flex-shrink: 0;
        background: #fbfbfb;
        margin: -20px -30px -20px 30px;
        padding: 15px 20px;
        border-left: 1px solid #e9e9e9;
        border-radius: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sample_preview .drawer_area{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        line-height: 0;
        overflow: hidden;
    }
    .sample_preview .signup_portal{
        padding: 15px;
        background: #f5f5f5;
    }
    .sample_preview .signup_portal .heading{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: 121525;
        margin-bottom: 15px;
        text-align: center;
    }
    .sample_preview .signup_portal .field_list li{
        padding: 7px 0;
    }
    .sample_preview .signup_portal .field_list li .label{
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 2px;
    }
    .sample_preview .signup_portal .field_list li .field_box{
        height: 24px;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        background: #fff;
    }
    .sample_preview .signup_portal .primarybtn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        border-radius: 3px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        margin: 15px 0;
    }
    .sample_preview .icon{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        border: 3px solid #fff;
        margin: 0 auto;
    }
    .sample_preview .signup_portal .icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        border: 2px solid #fff;
        margin: -45px auto 20px auto;
    }
    .sample_preview .signup_portal.no_cover .icon{
        margin-top: 0;
    }
    .sample_preview .banner{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .sample_preview .signup_portal .privacy{
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
        padding: 5px 0;
    }
    .sample_preview .signup_portal .privacy a{
        color: #2f7eed;
        text-decoration: none;
    }
    .sample_preview .signup_portal .copyright{
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
        padding: 3px 0;
    }

    .field-wpr {
        padding: 20px 30px;
        border-radius: 5px;
        margin-bottom: 15px;
        background: #fff;
    }

    @media(max-width: 1399px){
        .tabs_content .setting_wpr .form_grp {
            row-gap: 30px;
        }
        .tabs_content .setting_wpr .group_item{
            min-width: 190px;
        }
    }
    @media(max-width: 1199px){
        .settings_preview{
            flex-direction: column;
        }
        .settings_preview .sample_preview{
            width:auto;
            margin: 40px -20px;
            padding-top: 30px;
            border-left: 0;
            border-radius: 0 0 5px 5px;
            border-top: 1px solid #e9e9e9;
        }
        .settings_preview .sample_preview > *{
            max-width: 260px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media(max-width: 450px){
        .optin_list li{
            padding: 7px;
        }
        .optin_list li label{
            padding: 15px 10px;
        }
        .optin_list li .switch_option h5{
            font-size: 11px !important;
        }
    }
</style>
